body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  background-color: #f7f7f5;
  height: 100%;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin: 0 0 32px;
}

h3 {
  border-bottom: 1px solid #e7e7e7;
  font-size: 20px;
  margin-bottom: 18px;
  padding-bottom: 18px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

input[type="file"]::file-selector-button {
  height: 30px;
  line-height: 30px;
  padding: 0 16px;
  margin: 0 12px 0 0;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  background: #eeeeee;
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

input[type="file"] {
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #666666;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.App {
  background-color: #f7f7f5;
  font-size: 16px;
  height: 100%;
}

.App-logo {
  float: left;
  margin-right: 15px;
  height: 25px;
  width: 25px;
}

.App-header {
  background-color: #ffffff;
  color: #444444;
  font-size: 15px;
  height: 60px;
  padding: 10px;
}

.App-title {
  font-family: "Avenir";
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 960px;
  height: 100%;
  margin: 0 auto;
}

.header_appLogoArea {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.header_loggedInUser {
  display: flex;
  align-items: center;
}

.header_userEmail,
.header_serviceStatus {
  color: #333333;
  font-size: 14px;
  letter-spacing: 0.02em;
  margin: 0 0 0 16px;
}

.header_button {
  display: flex;
  align-items: center;
  color: #999999;
  font-size: 12px;
  height: 30px;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 0 12px;
  margin: 0 0 0 16px;
  cursor: pointer;
  transition: 0.2s;
}

.header_button:hover {
  text-decoration: none;
  color: #999999;
  opacity: 0.6;
}

.header_help {
  color: #333333;
  margin: 0 0 0 16px;
  font-size: 14px;
}

.header_help img {
  vertical-align: text-top;
  width: 12px;
  height: 17px;
  margin-right: 4px;
}

.navBar {
  display: flex;
  justify-content: flex-start;
  margin: 60px auto 0;
  max-width: 960px;
  background: #f8f8f8;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.navBar a {
  color: #555555;
}

.navItem {
  height: 110px;
  width: 25%;
  flex-shrink: 1;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #222222;
}

.navItem a {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #222222;
  height: 100%;
}

.navItem:first-of-type {
  border-radius: 10px 0 0 0;
}

.navItem:last-of-type {
  border-radius: 0 10px 0 0;
}

.navItem-selected {
  color: #f8ba11;
  background: white;
}

.navItem-selected a {
  color: #f8ba11;
}

.navItem-selected path {
  stroke: #f8ba11;
}

.navItem-selected .navIcon-fill {
  fill: #f8ba11;
}

.navIcon {
  display: block;
  height: 40px;
  margin: 0 auto 16px auto;
  text-align: center;
}

.nav-m-bk {
  background-color: #fff;
}

.detail-nav {
  display: flex;
  align-items: center;
}

.detail-nav > li {
  float: left;
  width: 24%;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.15em;
}

.detail-nav > li:not(:last-of-type) {
  margin: 0 8px 0 0;
}

.detail-nav > li > a {
  display: block;
  padding: 15px 15px;
}

.detail-nav > li > a {
  transition: all 0.15s ease;
  color: #000000;
  font-size: 16px;
  border-radius: 10px;
}

.detail-nav > li > a:hover,
.detail-nav > li > a:focus {
  background-color: rgba(246, 186, 17, 0.1);
}

.detail-nav > li > a > .badge {
  margin-left: 3px;
}

.detail-nav > .active > a > .badge {
  background: rgba(248, 186, 17, 0.2);
}

.detail-nav > li.active > a,
.detail-nav > li.active > a:hover,
.detail-nav > li.active > a:focus {
  color: #000000;
  font-weight: bold;
  background: rgba(248, 186, 17, 0.2);
}

.detailNav_navItemChild {
  padding: 15px 15px 15px 45px;
  width: auto !important;
  min-width: 140px;
  text-align: left !important;
}

.detailNav_navItemChild:before {
  content: "";
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: #000000;
  height: 2px;
  width: 20px;
}

.content {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  background: #ffffff;
  padding: 45px 50px 55px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.content:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 10px;
  border-radius: 0 0 10px 10px;
  background: #ffffff;
  z-index: 1;
}

.card {
  margin: 50px 0 0;
  position: relative;
}

.absoluteRight {
  position: absolute;
  right: 30px;
}

.inlineBlock {
  display: inline-block !important;
}

.displayBlock {
  display: block !important;
}

.liveBookingMessage {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: #333;
}

.liveBookingMessage.done:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}

.liveBookingMessage.done:before {
  background-image: url(/static/media/icon_done.44744ac1.svg);
}

.fixFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  width: 100%;
  max-width: 960px;
  display: block;
  height: 120px;
}

.fixFooterBtnArea {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixFooterInner {
  height: 100%;
}

.fixFooterInner .strongDeleteButton {
  margin: 0 60px 0 0;
}

.form > p:first-of-type {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #000000;
  margin: 0 0 48px;
}

.form .control-label {
  text-align: inherit;
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #000000;
}

.form-group:not(:last-of-type),
.form-group.itemData_shops {
  border-top: 1px solid #eeeeee;
  padding: 28px 0;
  margin: 0;
}

.form-group:last-of-type {
  margin: 0;
}

.formcaption {
  font-size: 11px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: #666666;
  padding: 12px;
  margin: 8px 0 0;
}

.formcaption p {
  margin: 0;
}

.tagmust {
  min-width: 37px;
  display: inline;
  background-color: #f8ba11;
  height: 17px;
  line-height: 17px;
  padding: 0 4px;
  margin-left: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2em;
  border-radius: 4px;
}

.form-control {
  height: 44px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #000000;
}

.form-control::-webkit-input-placeholder {
  font-weight: 300;
  color: #aeaeae;
}

.form-control::placeholder {
  font-weight: 300;
  color: #aeaeae;
}

.form .btn-warning {
  min-width: 300px;
  height: 50px;
  background: #f8ba11;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.3em;
  color: #ffffff;
  text-shadow: none;
  box-shadow: none;
}

.delivered {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  padding: 0 8px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f8ba11;
  border-radius: 20px;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.15em;
  color: #f8ba11;
}

.error {
  color: #ed5454;
  font-size: 12px;
  line-height: 1.5;
}

.warning {
  margin: 4px 0 0;
  color: #666666;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

.success {
  color: #28a745;
  font-size: 12px;
  line-height: 1.5;
}

.green {
  color: green;
}

.disabled {
  opacity: 0.5;
}

.cell {
  display: inline-block;
  width: 120px;
}

.nameCell {
  display: inline-block;
  width: 200px;
  font-size: 14px;
  font-weight: normal;
  margin: 0 12px;
  color: #555;
}

.streamerCell {
  display: inline-block;
  width: 89%;
  font-size: 14px;
  font-weight: normal;
  margin: 0 12px;
  color: #555;
  word-break: break-all;
}

.listIconClose {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.tableHalf {
  width: 50%;
}

.tableHalf li {
  position: relative;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0;
}

.items .card {
  margin: 30px 0 0;
}

.itemData_downloadTsvSpec {
  float: right;
}

.itemData .card {
  padding: 0;
  margin: 50px 0 0;
}

.itemData .col-sm-8 {
  padding-right: 0;
}

.itemSearch .ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
}
.itemSearch .ais-Hits-item {
  display: flex;
}
.itemSearch .ais-Hits-item:not(:nth-of-type(4n)) {
  width: calc(200px + (100% - 200px * 4) / 3);
  flex-shrink: 1;
}

.itemSearch .ais-Hits-item:nth-of-type(4n) {
  width: 200px;
}

.itemSearch {
  margin: 0 0 36px;
  display: flex;
  flex-direction: column;
}

.itemSearch_unit {
  width: 200px;
}

.itemSearch_unit.head {
  flex-grow: 1;
}

.itemSearch_unit img {
  width: 200px;
  height: 240px;
  margin: 0 0 16px;
  object-fit: contain;
}

.itemSearch_unit .goodsNameCell {
  display: block;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #000000;
  word-break: break-all;
}

.itemSearch_unit .ddGoodsTitleCell {
  margin: 0 0 6px;
  font-weight: bold;
}

.itemSearch_unit .ddBrandNumberCell {
  margin: 0 0 6px;
}

.itemSearch_unit .ddPriceCell {
  margin: 0 0 10px;
}

.itemSearch_unit .ddLastUpDateCell {
  margin: 0 0 16px;
}

.unit img {
  display: inline-block;
  width: 120px;
  height: auto;
}

.unit {
  display: flex;
  display: -ms-flexbox;
  justify-content: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;
  padding: 12px 32px;
}

.unit:last-child {
  border-bottom: none;
}

.input {
  margin: 10px 0 10px;
  width: 300px;
}

.space {
  margin-top: 20px;
}

.marginRight {
  margin-right: 5px;
}

.marginRightL {
  margin-right: 10px;
}

.hiddenOverflow {
  overflow: hidden;
}

li.liveBanner {
  float: left;
  margin: 10px;
  height: 200px;
  width: 255px;
  position: relative;
  text-align: center;
}

.liveBanner img {
  height: 200px;
  width: 200px;
  vertical-align: top;
  object-fit: contain;
}

.liveBanner img.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
}

li.liveTelop {
  float: left;
  margin: 10px;
  height: 136px;
  width: 190px;
  position: relative;
  text-align: center;
  margin-bottom: 16px;
}

.broadcastingItemList li.liveTelop {
  float: left;
  margin: 4px;
  height: 140px;
  width: 124px;
  position: relative;
  text-align: center;
}

.liveTelop img,
.broadcastingItemList li.liveTelop img {
  height: 90px;
  width: 120px;
  vertical-align: top;
  object-fit: contain;
}

.liveTelop img.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
}

.liveTelop img.moveBack {
  position: absolute;
  left: 0;
  top: 33px;
  width: 24px;
  height: 24px;
}

.liveTelop img.moveNext {
  position: absolute;
  right: 0;
  top: 33px;
  width: 24px;
  height: 24px;
}

.form-control.telop-url {
  width: 200px;
  margin-top: 16px;
}

li.liveItem {
  float: left;
  margin: 0 10px 0 0;
  width: 128px;
  position: relative;
}

.liveItem img {
  width: 128px;
  vertical-align: top;
  object-fit: cover;
}

.liveItem span {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  margin-top: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #000000;
  height: 53px;
}

.liveItem img.close {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 16px;
  height: 16px;
}

.liveItem img.moveBack {
  position: absolute;
  left: 0;
  top: 46px;
  width: 24px;
  height: 24px;
}

.liveItem img.moveNext {
  position: absolute;
  right: 0;
  top: 46px;
  width: 24px;
  height: 24px;
}

.moveBack,
.moveNext {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.back:hover,
.back:focus,
.next:hover,
.next:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.publisherArea {
  width: 405px;
  height: 720px;
  margin-bottom: 80px;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.publisherRotated {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.clear {
  clear: both;
}

.profileImage {
  max-width: 200px;
  margin-top: 10px;
}

/* button */
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.blackBtn {
  display: block;
  background: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.15em;
  color: #222222;
  border: 1px solid #333333;
}

.grayBtn {
  display: block;
  background: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.15em;
  color: #999999;
  border: 1px solid #999999;
}

.redBtn {
  background: rgb(200, 75, 75) !important;
  color: #ffffff !important;
  margin-left: 4px;
}

.greenBtn {
  background: rgb(88, 206, 108) !important;
  color: #ffffff !important;
  margin-left: 4px;
}

.largeBtn {
  min-width: 200px;
}

.normalBtn,
.normalBtn-strongColor,
.strongBtn,
.cautionBtn {
  padding: 0 24px;
  border-radius: 4px;
  background-color: #fff;
  text-shadow: none;
  cursor: pointer;
}

.cautionBtn {
  height: 34px;
  line-height: 34px;
  color: #e12a2a;
  border: 1px solid #e12a2a;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-align: center;
}

a:hover.cautionBtn {
  color: #e12a2a;
  background-color: #fcebeb;
  border: 1px solid #e12a2a;
  text-decoration: none;
}

.normalBtn {
  height: 34px;
  line-height: 34px;
  color: #666;
  border: 1px solid #666;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-align: center;
}

.showBtn {
  margin-left: 4px;
}

a:hover.normalBtn {
  color: #666;
  background-color: #f1f1f1;
  border: 1px solid #666;
  text-decoration: none;
}

.normalBtn-strongColor {
  height: 34px;
  line-height: 34px;
  color: #fff;
  background-color: #f8ba11;
  border: none;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-align: center;
}

a:hover.normalBtn-strongColor,
button:hover.normalBtn-strongColor {
  color: #fff;
  background-color: #fac534;
  text-decoration: none;
}

.strongBtn {
  display: inline-block;
  width: 300px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  background-color: #f8ba11;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.3em;
}

a:hover.strongBtn,
button:hover.strongBtn {
  color: #fff;
  background-color: #fac534;
  border: 1px solid #f8ba11;
  text-decoration: none;
}

.strongDeleteButton {
  display: inline-block;
  width: 150px;
  height: 50px;
  font-size: 12px;
  letter-spacing: 0.15em;
  color: #e12a2a;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #e12a2a;
}

a:hover.strongDeleteButton,
button:hover.strongDeleteButton {
  color: #e12a2a;
  background-color: #fcebeb;
  border: 1px solid #e12a2a;
  text-decoration: none;
}

.warningBtn {
  display: inline-block;
  width: 300px;
  height: 50px;
  line-height: 50px;
  color: #f8ba11;
  background-color: #ffffff;
  border: 1px solid #f8ba11;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.3em;
}

a:hover.warningBtn,
button:hover.warningBtn {
  color: #fff;
  background-color: #fac534;
  border: 1px solid #f8ba11;
  text-decoration: none;
}

.inlineBtn {
  display: inline-block;
  margin-left: 12px;
}

.inlineBtn:last-child {
  margin-right: 0;
}

.floatBtn {
  float: right;
  margin: 8px 40px 8px 8px;
  display: inline-block;
}

button#resolution,
button#shop {
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  background-image: none;
}

/* radio button */

.radio-inline {
  padding: 0 0 0 24px;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #000000;
}

.radio-inline + .radio-inline {
  margin-left: 24px;
}

input[type="radio"] {
  visibility: hidden;
  width: 16px;
  height: 16px;
  margin-left: -24px !important;
}

input[type="radio"]:before {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  border-radius: 8px;
}

input[type="radio"]:checked:before {
  visibility: visible;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #f8ba11;
  border-radius: 8px;
}

input[type="radio"]:checked:after {
  visibility: visible;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  margin: 3px;
  background: #f8ba11;
  border: 1px solid #f8ba11;
  border-radius: 8px;
}

/* checkbox */

.checkbox {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #000000;
}

.checkbox label {
  padding-left: 24px;
}

input[type="checkbox"] {
  visibility: hidden;
  width: 16px;
  height: 16px;
  margin-left: -24px !important;
}

input[type="checkbox"]:before {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  border-radius: 2px;
}

input[type="checkbox"]:checked:before {
  visibility: visible;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #999999;
  color: #999999;
  background: #999999;
  border-radius: 2px;
}

/* searchbox */

.searchBox {
  margin: 10px 0 15px;
}

input.ais-SearchBox-input {
  width: 200px;
  height: 38px;
  line-height: 38px;
  padding: 0 52px 0 12px;
  margin: 0;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15em;
  color: #666666;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

input.ais-SearchBox-input::-webkit-input-placeholder {
  color: #aeaeae;
}

input.ais-SearchBox-input::placeholder {
  color: #aeaeae;
}

button.ais-SearchBox-submit {
  width: 40px;
  height: 38px;
  border: none;
  padding: 0;
  margin: 0 0 0 -40px;
  background: url(/static/media/icon_search.5f5805eb.svg) no-repeat center;
}

svg.ais-SearchBox-submitIcon {
  visibility: hidden;
}

button.ais-SearchBox-reset {
  background-color: #ffffff;
  border: none;
}

/* dropdown */

.dropdown {
  margin: 5px 0 15px 0;
}

.dropdown button {
  height: 38px;
  line-height: 38px;
  padding: 0 12px !important;
  font-size: 12px;
  letter-spacing: 0.15em;
  color: #666666;
  border: 1px solid #dbdbdb !important;
  border-radius: 4px;
  box-shadow: none;
}

.dropdown-toggle {
  box-shadow: none !important;
}

.dropdown .caret {
  background: url(/static/media/icon_arrow_down.cb4c1177.svg) no-repeat center;
  width: 16px;
  height: 16px;
  border: none;
  margin: 0 0 0 12px;
}

/* table-livebooking */

.testLiveList {
  display: flex;
  background: #fafafa;
  margin: 0 0 56px;
  padding: 20px 10px;
  align-items: center;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.titleCell {
  font-weight: bold;
}

.dtCheckboxCell,
.checkboxCell {
  width: 32px;
  margin: 0 0 0 4px;
}

.checkboxCell .nomargin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.dtTitleCell,
.titleCell {
  width: 200px;
  text-align: left;
}

.dtSessionDateTimeCell,
.ddSessionDateTimeCell {
  width: 120px;
}

.dtSessionActionCell,
.ddSessionActionCell {
  width: 180px;
  text-align: center;
}

.dtSessionDeleteCell,
.ddSessionDeleteCell {
  width: 50px;
  text-align: center;
}

/* table-itemlist */

.itemDataTableList .dtshopType,
.itemDataTableList .ddshopType {
  width: 20%;
  margin: 0;
}

.itemDataTableList .dtshopName,
.itemDataTableList .ddshopName {
  width: 40%;
  margin: 0;
}

.itemDataTableList .dtIdCell,
.itemDataTableList .ddIdCell {
  width: 40%;
  margin: 0;
}

.itemDataTableList .ddTablelist:last-of-type {
  border-bottom: none;
}

/* pager */

.pager .pagination,
.message {
  text-align: center;
}

.pager .pagination li.prev,
.pager .pagination li.next {
  width: 90px;
  height: 30px;
}

.pager .pagination li {
  position: relative;
  background: #fff;
  display: inline;
  margin-right: 20px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
}

.pager .pagination li:last-child {
  margin-right: 0px;
}

.pager .pagination li a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #999999;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  text-decoration: none;
}

.pager .pagination li a span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pager .pagination li a:hover {
  color: #333;
  background: #f1f1f1;
}

.pager .pagination li a.active {
  color: #f8ba11;
  font-weight: bold;
  border: 2px solid #f8ba11;
}

.pager .pagination li a.active:hover {
  color: #f8ba11;
  font-weight: bold;
  background: #fdf2d6;
}

/* register */

.register.content {
  margin: 60px auto 0;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.register.content:after {
  display: none;
}

.register .card {
  padding: 0;
}

.register .form-group {
  border: none;
  display: flex;
  align-items: center;
}

/* login */

.loginContainer {
  margin: 0 auto;
  padding-top: 200px;
  text-align: center;
}

.AppTitleContainer {
  display: inline-block;
  margin-left: -10px;
  height: 60px;
}

.cardMini {
  background-color: white;
  padding: 50px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: block;
  max-width: 480px;
}

.formUplabel {
  text-align: left;
}

.label-up {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #666666;
}

.login_passwordArea {
  margin: 0 0 40px;
}

/* home */

.home .card {
  padding: 0;
  letter-spacing: 0.1em;
}

/* shop */

.shop .card {
  padding: 0;
}

.shop .form-group {
  display: flex;
  justify-content: space-between;
}

.shop .control-label {
  width: 270px;
  max-height: 44px;
  display: flex;
  align-items: center;
  margin: 0;
}

.shop .form-group .tagmust {
  margin-right: 70px;
}

.shop .col-sm-8 {
  padding-right: 0;
  flex-grow: 1;
}

/* theme */

.theme .form {
  margin: 0 0 60px;
}

.form-group:last-of-type {
  margin: 0;
}

.theme .form-group {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.theme .control-label {
  width: 320px;
  max-height: 44px;
  display: flex;
  align-items: center;
  margin: 0;
  flex-grow: 0 !important;
}

.theme .form-group .tagmust {
  margin-right: 70px;
}

.theme .form-group .error,
.theme .form-group .warning {
  margin: 10px 0 0;
}

.theme .theme_textInput-wide {
  width: 100% !important;
}

.theme .col-sm-6 {
  flex-grow: 1;
}

.theme .form-control {
  width: 200px;
}

.theme_idLabel {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

/* scenario */

.scenario .form {
  margin: 0 0 60px;
}

.scenario .form-group {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.scenario .control-label {
  width: 320px;
  max-height: 44px;
  display: flex;
  align-items: center;
  margin: 0;
  flex-grow: 0 !important;
}

.scenario .form-group .error,
.scenario .form-group .warning {
  margin: 10px 0 0;
}

.scenario .col-sm-6 {
  flex-grow: 1;
}

/* scenarios */

.scenarios_listItem {
  color: #000000;
  font-size: 12px;
  font-weight: normal;
  word-break: break-all;
  line-height: 17px;
  letter-spacing: 0.1em;
}

.scenarios_listRow {
  padding: 20px 0;
}

.scenarios_listRow:not(:last-of-type) {
  border-bottom: 1px solid #e7e7e7;
}

/* streamer */

.streamer .form-group {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.streamer .control-label {
  width: 270px;
  max-height: 44px;
  display: flex;
  align-items: center;
  margin: 0;
}

.streamer .form-group .tagmust {
  margin-right: 70px;
}

/* preview */

.preview {
  padding: 45px 50px 80px;
}

.preview .form-group {
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.preview .row {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: 0;
}

.preview .form-group .col-sm-8 {
  padding-right: 0;
  flex-grow: 1;
}

.preview_descriptionRow {
  margin: 0 0 16px !important;
}

.preview_titleContainer {
  display: flex;
  align-items: center;
}

.preview_titleContainer .testTag {
  white-space: nowrap;
  margin: 0 0 0 16px;
}

.preview .checkbox {
  height: 34px;
}

.preview .ais-InstantSearch_root {
  margin: 0 0 60px;
}

.preview .liveItem {
  margin: 0 10px 16px 0;
}

.preview .liveItem img.moveBack {
  top: 64px;
}

.preview .liveItem img.moveNext {
  top: 64px;
}

.preview_liveItemImage {
  height: 152px;
}

/* previews */

.previews_detailNavButtonsArea {
  float: right;
  display: flex;
  margin: 0 0 0 auto;
}

.previews_trashButton {
  padding: 5px 35px !important;
}

.previews .dtTablelist,
.previewTrash .dtTablelist {
  display: flex;
  min-height: 44px;
  padding: 0 10px 0 0;
  align-items: center;
}

.previews .ddTablelist,
.previewTrash .ddTablelist {
  display: flex;
  padding: 20px 10px 20px 0;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #000000;
}

.previews .dtIdCell,
.previews .ddIdCell {
  display: flex;
  flex-direction: column;
  width: 240px;
  margin: 0 auto 0 0;
}

.previewTrash .dtIdCell,
.previewTrash .ddIdCell {
  display: flex;
  flex-direction: column;
  width: 320px;
  margin: 0 auto 0 0;
}

.previews .dtDateTimeCell,
.previews .dateTimeCell {
  width: 180px;
  margin: 0;
}

.previewTrash .dtDateTimeCell,
.previewTrash .dateTimeCell {
  width: 240px;
  margin: 0;
}

.previews_liveName,
.previewTrash_liveName,
.previews_liveListTestId,
.previewTrash_liveListTestId {
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.1em;
  margin: 12px 0 0;
}

.previews_liveListTestId,
.previewTrash_liveListTestId {
  color: #ed2424;
}

.previews_dtButtonsCell,
.previews_ddButtonsCell {
  display: flex;
  min-width: 408px;
  flex-shrink: 0;
}

.previewTrash_dtButtonsCell,
.previewTrash_ddButtonsCell {
  display: flex;
  min-width: 180px;
  flex-shrink: 0;
}

.previews_dtButtonsCell,
.previewTrash_dtButtonsCell {
  justify-content: flex-end;
}

.previewTrash_ddButtonsCell a {
  margin: 0 0 0 auto;
}

.previews_dtButtonsCell .cautionBtn,
.previewTrash_dtButtonsCell .cautionBtn {
  margin: 0 4px 8px 0;
}

.previews_ddButtonsCell,
.previewTrash_ddButtonsCell {
  justify-content: space-between;
}

.preview_archiveImageUrl {
  margin-top: 8px !important;
}

/* live / broadcastingSidebar */

.live {
  display: flex;
  flex-wrap: wrap;
}

.live_left {
  width: calc(50% - 24px);
  margin: 0 48px 0 0;
  flex-grow: 1;
  flex-shrink: 0;
}

.live_right {
  width: calc(50% - 24px);
  flex-grow: 1;
  flex-shrink: 1;
}

.live_initialInfo {
  margin: 0 0 24px;
  padding: 0 0 12px;
  border-bottom: 1px solid #eeeeee;
}

.live_titleLabel {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 20px !important;
  padding: 0 0 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #666666;
  border-bottom: 1px solid #dbdbdb;
}

.live_titleLabel_survey {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 8px !important;
  padding: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #666666;
}

.live_liveId {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #000000;
  display: block;
  margin: 0 0 12px;
}

.live_liveName {
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #000000;
  margin: 0 0 12px;
  overflow-wrap: break-word;
  word-break: break-all;
}

.live_liveItemList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.live_ctaLabel,
.live_isLoadingLabel {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #000000;
}

.live .liveItem {
  margin: 0 0 16px;
}

.live .liveItem:not(:nth-of-type(3n)) {
  margin-right: max(calc((100% - 128px * 3) / 2), 0px);
}

.live_liveItemImage {
  height: 152px;
}

.live_rightButtonsArea {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.broadcastingTitle {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}

.broadcastingTitle,
.titleContainer {
  position: relative;
}

.broadcastingTag,
.tagPosition {
  position: absolute;
  top: 0;
  right: 0;
}

.broadcastingTagInner,
.tagStyle {
  min-width: 48px;
  padding: 5px 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  line-height: 1;
}

.broadcastingOffline {
  background-color: #f00;
}

.broadcastingPrivate {
  background-color: #f00;
}

.broadcastingBefore {
  background-color: #555;
}

.testTag {
  background-color: #e12a2a;
}

.broadcastingPreview {
  background-color: #f6a388;
}

.broadcastingStreaming {
  background-color: #69bf7c;
}

.itemDetail .card {
  margin: 30px 0 0;
}

.itemDetail .itemDetail_detailTab {
  position: relative;
  padding: 15px 15px 15px 45px;
  width: auto;
}

.itemDetail .itemDetail_detailTab:before {
  content: "";
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: #000000;
  height: 2px;
  width: 20px;
}

.itemDetailArea {
  display: flex;
  position: relative;
  margin: 0 0 56px;
}

.btn-narrow {
  padding: 0 10px;
}

.itemDetail .imgDetail {
  display: inline-block;
}

.itemDetail .imgDetail > img {
  width: 300px;
}

.textDetail {
  margin-left: 24px;
  width: 100%;
}

.textDetail > div {
  margin-bottom: 8px;
}

.itemDetail_name,
.itemDetail_skuIdList {
  margin: 0 0 24px !important;
}

.itemDetail_nameLabel {
  display: block !important;
}

.itemDetail_nameContent {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #000000;
}

.itemDetail_skuIdList {
  display: flex;
}

.itemDetail_skuIdList .titleLabel {
  white-space: nowrap;
}

.itemImagesList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.itemImagesArea {
  margin-top: 20px;
}

.itemImagesList li {
  margin-bottom: 10px;
}

.titleContent.caption {
  font-size: 14px;
  color: #000000;
  background: #f8f8f8;
  border-radius: 4px;
  min-height: 150px;
  padding: 16px;
}

.itemDetail_backButton {
  width: 100%;
  margin: 40px 0;
  text-align: center;
}

/* table */

.tableScrollArea {
  overflow: scroll;
  max-height: 500px;
}

.dtTablelist {
  padding: 10px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #dbdbdb;
  font-size: 12px;
  font-weight: bold;
  word-break: break-all;
  letter-spacing: 0.15em;
  color: #666666;
}

.surveyComponent {
  padding: 8px;
  background-color: #f8f8f8;
  margin-bottom: 8px;
}

.surveyQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.surveyOption {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 4px;
}

.surveyOptionPercent {
  flex-shrink: 0;
  margin-bottom: 4px;
}

.w110 {
  width: 110px;
}

.w200 {
  width: 200px;
}

.w500 {
  width: 500px;
}

.ddTablelist {
  color: #000000;
  font-size: 12px;
  font-weight: normal;
  word-break: break-all;
  line-height: 17px;
  letter-spacing: 0.1em;
}

.ddTablelist {
  border-bottom: 1px solid #e7e7e7;
  padding: 16px 0;
}

.ddTablelist span {
  vertical-align: middle;
}

.ddTablelist span a {
  cursor: pointer;
  text-decoration: none;
}

.titleLabel {
  font-size: 13px;
  font-weight: bold;
  color: #999999;
  display: inline-block;
  margin-bottom: 5px;
  padding-right: 12px;
  letter-spacing: 0.1em;
}

.titleContent {
  font-size: 15px;
  letter-spacing: 0.1em;
  color: #000000;
}

.broadcastingMainInfo,
.broadcastingCommentList,
.broadcastingItemList {
  background-color: #fff;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.broadcastingPreviewComment {
  margin-bottom: 20px;
}

.broadcastingCommentList,
.broadcastingItemList {
  margin-top: 20px;
}

.broadcastingCommentListInner {
  padding: 0;
  overflow: auto;
  height: 200px;
}

.live p {
  margin: 0;
}

.live_commentTitleArea {
  position: relative;
}

.live_commentBtn {
  position: absolute;
  top: -5px;
  right: 10px;
}

.live_commentContainer {
  background-color: #ecfd63;
}

.live_commentName {
  font-weight: bold;
}

.live_commentName,
.live_commentText {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  overflow-wrap: break-word;
  word-break: break-all;
}

.broadcastingCommentListInner li {
  line-height: 2;
}

.broadcastingItemList ul {
  overflow: hidden;
}

.emphasizeNumber {
  font-size: 32px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.positionRightupper {
  text-align: right;
}

div#publisher,
div#hardware-setup {
  position: relative;
  margin-top: 20px;
}

.opentok-hardware-setup {
  width: 405px;
}

.stabilizing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}

.stabilizing-message {
  font-size: 2rem;
  letter-spacing: 0.1em;
  color: #fff;
}

.stabilizing-cancel {
  color: #fff;
  background-color: #c00;
  border: none;
  border-radius: 4px;
  padding: 16px;
  transition: 0.3s;
}
.stabilizing-cancel:disabled {
  pointer-events: none;
  background-color: #ccc;
}
.stabilizing-cancel:hover {
  background-color: #f00;
}

.stabilizing-countdown {
  font-size: 10rem;
  color: #fff;
}

/* messages */

.messages .card {
  padding: 0;
}

.messages .dtTablelist {
  display: flex;
  min-height: 44px;
  padding: 0 10px 0 0;
  align-items: center;
}

.messages .ddTablelist {
  display: flex;
  padding: 20px 0;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #000000;
}

.messages_titleArea {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

.messages_fixedCommentTitleArea {
  width: 100%;
  margin: 0 0 20px;
  padding: 0 0 12px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #666666;
  border-bottom: 1px solid #dbdbdb;
}

.for_space {
  float: left;
  margin: 0 0 0 20px;
  width: 200px;
  min-height: 1px;
}

.fix_comment {
  margin-top: 10px;
}

.messages_fixedCommentDescription {
  font-size: 11px;
  font-weight: normal;
}

.messages_fixedMessageFormGroup {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.messages_fixedMessageFormGroup .normalBtn {
  margin-right: 32px;
}

.messages_fixedMessageFormGroup .is-streamer-comment {
  margin-right: 10px;
}

.messages_fixedMessageFormGroup .is-streamer-comment input {
  margin-right: 5px;
  vertical-align: text-bottom;
}

.messages_fixedMessageFormError {
  margin: 10px 0 0 !important;
}

.messages_dateTimeCell {
  width: 160px;
  padding: 0 0 0 0;
}

.messages_nameCell {
  width: 120px;
  padding: 0 10px 0 0;
}

.messages_textCell {
  width: 300px;
  flex-grow: 0;
}

.messages_statusCell {
  width: 80px;
  flex-grow: 1;
}

/* API Data */

.apiData_titleArea {
  margin: 40px 0 0;
}

.apiData .card {
  padding: 0;
}

.apiData_initialList {
  border-top: 1px solid #eeeeee;
  margin: 0 0 32px;
}

.apiData_initialListItem {
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.apiData_initialList-dt {
  width: 320px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #000000;
  flex-shrink: 0;
}

.apiData_initialList-dd {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #000000;
}

.apiData_datePicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.apiData_datePicker .DateRangePicker {
  z-index: 2;
}

.apiData_datePicker .DateRangePickerInput {
  position: relative;
  border: none;
  margin: 0 0 0 -16px;
}

.apiData_datePicker .DateRangePickerInput_arrow {
  visibility: hidden;
}

.apiData_datePicker .DateRangePickerInput_arrow:before {
  visibility: visible;
  position: absolute;
  content: "\301C";
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #000000;
}

.apiData_datePicker .DateInput_input {
  padding: 0 16px;
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #000000;
}

.apiData_progressMessage {
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.15em;
  color: #000000;
  margin-bottom: 12px;
}

.apiData_reportList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 40px;
}

.apiData_reportList li {
  display: flex;
  align-items: center;
  width: 50%;
  height: 64px;
  border: 1px solid #cccccc;
  margin: 0 -1px -1px 0;
}

.apiData_reportList-dt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 44px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #000000;
  border-right: 1px solid #cccccc;
}

.apiData_reportList-dd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #000000;
}

.apiData_subtitle {
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.15em;
  color: #000000;
  margin: 30px 0 15px;
}

.apiData_bottomArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 0;
}

.apiData_downloadButtons {
  display: flex;
}

.apiData_downloadButton {
  min-width: 300px;
  width: auto;
  letter-spacing: 0.1em;
}

.apiData_downloadButton + .apiData_downloadButton {
  margin: 0 0 0 40px;
}

.liveBookingMessage.done:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
  vertical-align: middle;
  margin-right: 8px;
}
.liveBookingMessage.done:before {
  background-image: url(/static/media/icon_done.44744ac1.svg);
}

.iconDoing:before {
  background-image: url(/static/media/icon_doing.20f5fb43.svg);
}

.apidataOverall table,
.apidataOverall th,
.apidataOverall td,
.apidataOverall tr {
  border-spacing: 0;
  border-collapse: collapse;
}
.apidataOverall th,
.apidataOverall td {
  border: 1px solid #ccc;
}
.apidataOverall td {
  text-align: right;
}
.apidataOverall tr:first-child {
  border-top: none;
}
.apidataOverall table {
  border: none;
  width: 100%;
  border-collapse: collapse;
}
.apidataOverall th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  border-left: none;
}
.apidataOverall th.title {
  background-color: #fff;
}
.apidataOverall th:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 1px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #ddd;
}
.apidataOverall tr:first-child th {
  border-top: none;
}
.apidataOverall tbody tr:last-child th,
.apidataOverall tbody tr:last-child td {
  border-bottom: none;
}
.apidataOverall td:last-child,
.apidataOverall th:last-child {
  border-right: none;
}

.apidataOverall {
  overflow: scroll;
  border: 1px solid #ddd;
}

.apidataOverall th {
  min-width: 80px;
  padding: 5px 10px;
  font-size: 12px;
  color: #555;
  background-color: #f1f1f1;
  font-weight: bold;
}
.apidataOverall td {
  background-color: #fff;
  font-size: 12px;
  color: #555;
  font-weight: bold;
  padding-right: 10px;
}

.apidataPerLive th:nth-child(-n + 5)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 1px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #ddd;
}
.apidataPerLive th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  border-left: none;
  word-break: break-all;
}
.apidataPerLive th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 80px;
  border-collapse: separate;
  word-break: break-all;
}
.apidataPerLive th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  left: 160px;
  border-collapse: separate;
  word-break: break-all;
}
.apidataPerLive th:nth-child(4) {
  position: -webkit-sticky;
  position: sticky;
  left: 240px;
  border-collapse: separate;
  word-break: break-all;
}
.apidataPerLive th:nth-child(5) {
  position: -webkit-sticky;
  position: sticky;
  left: 320px;
  border-collapse: separate;
  word-break: break-all;
}

.reportTableContainer p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15em;
  color: #000000;
}

/* Message styles for theme */

.whiteColorMessage {
  color: white;
  text-shadow: 1px 1px 1px rgb(0, 0, 0, 60%);
  line-height: 1.4 !important;
}

.darkColorMessage {
  color: black;
  text-shadow: 1px 1px 1px rgb(255, 255, 255, 60%);
  line-height: 1.4 !important;
}

.withBackgroundMessage {
  color: white;
  line-height: 1.4 !important;
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.25);
}

/* Streamer comment */

.form-control.streamer-name {
  width: 200px;
}
.form-control.streamer-text {
  margin-right: 10px;
  width: 400px;
}
.form-control.archive-image-url {
  width: 100%;
}

/* Receptionst comment */
.form-control.receptionst-name {
  width: 80px;
}
.form-control.receptionst-text {
  margin-right: 10px;
  width: 500px;
}

/* System report */

.systemReport_date {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #666666;
}

/* drag-and-drop */

.dropzoneContainer {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: rgb(238, 238, 238);
  border-style: dashed;
  background-color: rgb(250, 250, 250);
  outline: none;
  transition: border 0.24s ease-in-out 0s;
}

.dropzone:hover {
  background-color: #f5f5f5;
}

.reportEdit,
.reportCancel {
  margin-left: 8px;
}

.system-status {
  font-size: 12px;
  margin-top: -2px;
}

.ml-0 {
  margin-left: 0 !important;
}

.scenario-head {
  padding-top: 20px;
}

.scenario-item {
  margin-bottom: 6px;
}
.scenario-item-col {
  display: flex;
}
.scenario-item-col .delete {
  margin: 0 2px;
}
.reception-comment {
  display: flex;
}
.reception-comment .receptionist-name {
  font-weight: bold;
}

.reception-comment .reception-content {
  padding-left: 5px;
  flex: 1 1;
}
.reception-date {
  font-size: 10px;
  font-style: italic;
  color: #666666;
}

.information-bar {
  background-color: #f8ba11;
  margin: 0;
  padding: 10px;
  text-align: center;
}

.information-bar-link {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.information-bar-link:focus,
.information-bar-link:hover {
  color: #fff;
  text-decoration: none;
}

.information-bar-text {
  font-size: 16px;
}

.information-icon {
  margin-left: 10px;
}

.information-icon:focus,
.information-icon:hover {
  text-decoration: none;
}

.information-icon-new {
  position: relative;
  background-color: #f8ba11;
  color: #fff;
  font-size: 9px;
  padding: 3px;
  top: -10px;
}

.information-label-new {
  position: relative;
  background-color: #f8ba11;
  color: #fff;
  font-size: 9px;
  padding: 3px;
  top: -2px;
  right: -4px;
}

.informations-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.informations-modal-content {
  background: #fff;
  min-width: 400px;
  min-height: 400px;
  width: 50%;
  margin: 0;
  padding: 0;
  z-index: 4;
}

.informations-modal-head {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 0 0 5px 0;
  padding: 2px;
}

.informations-modal-close {
  color: #000;
  position: relative;
  top: 6px;
  margin: 0;
  padding: 0;
}

.informations-modal-content-data {
  font-size: 12px;
  margin-top: 2px;
  padding: 5px 15px;
}

.informations-modal-content-data:hover {
  background-color: rgba(248, 186, 17, 0.2);
}

.informations-modal-content-data a {
  color: #000;
  text-decoration: none;
}

.informations-modal-content-data a:focus,
.informations-modal-content-data a:hover {
  text-decoration: none;
}

.informations-modal-content-data-date {
  display: inline-block;
  width: 6em;
}

.informations-modal-content-data-text {
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

